import React from "react";
import Nav from "../components/Intake/Nav";
import AffiliateWhyHubble from "../components/Intake/AffiliateWhyHubble"
import AffiliatePress from "../components/Intake/AffiliatePress"
import OrderSection from "../components/Intake/OrderSection";
import "../styles/pages/intake-hydro-skyhy.scss"
import ContactsCharacteristics from "../components/Intake/ContactsCharacteristicsV3";
import AffiliateCallToAction from '../components/Intake/AffiliateCallToAction';
import SignupProducts from "../components/Intake/SignupProducts";
import HomeCtaAvg from "../components/HomeCtaAvg";
import KeywordHero from "../components/Intake/KeywordHero";

const IntakePage = () => {
  return (
    <div className="hubble-content-hydro-skyhy v3">
      <Nav isV3/>
      <KeywordHero keyword="dis"/>
      <AffiliateCallToAction isIntake/>
      <ContactsCharacteristics isV3/>
      <SignupProducts/>
      <AffiliateWhyHubble isV3/>
      <HomeCtaAvg/>
      <AffiliatePress/>
      <OrderSection isHydroSkyhy isIronclad={true} isV3 oneEyeTest={false}/>
    </div>
  );
}

export default IntakePage
